<template>
    <b-row>
      <b-col>
        <b-card>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-3">
            <download-excel
              class="btn btn-primary pull-right ml-3"
              :fetch="fetchData"
              :fields="download.header"
              :before-generate="startDownload"
              :before-finish="finishDownload"
              :name="'Зээлийн түүх.xls'"
              v-if="items != undefined && items.length > 0"
            >
              <b-spinner small type="grow" v-show="download.loading"></b-spinner>
              <span v-show="download.loading" class="ml-1">Уншиж байна</span>
              <i class="fa fa-download" v-show="!download.loading"></i>
              <span v-show="!download.loading" class="ml-1">Excel татах</span>
            </download-excel>
            <b-row class="clearfix">
              <b-col class="col-md-3">
                <multiselect
                  v-model="selected_merchant"
                  :options="merchants"
                  :searchable="true"
                  placeholder="Merchant"
                  :close-on-select="true"
                  :option-height="10"
                  :custom-label="nameWithCode"
                  track-by="id"
                >
                  <!-- <template slot="singleLabel" slot-scope="props">
                    {{ props.option.name + " | " + props.option.code }}
                  </template> -->
                  <template slot="option" slot-scope="props">
                    {{ props.option.code + " | " + props.option.name }}
                  </template>
                </multiselect>
              </b-col>
              <b-col>
                <b-input-group>
                  <b-input-group-prepend is-text
                    ><i class="fa fa-calendar"></i
                  ></b-input-group-prepend>
                  <date-picker
                    v-model="startAt"
                    :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                    placeholder="Эхлэх огноо"
                  ></date-picker>
                  <date-picker
                    v-model="endAt"
                    :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                    placeholder="Дуусах огноо"
                  ></date-picker>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row class="clearfix">
              <b-col>
                <div class="pull-right">
                  <b-form-select
                    v-model="query.per_page"
                    :options="[5, 10, 25, 100, 500]"
                    v-if="items != undefined && items.length > 0"
                  >
                  </b-form-select>
                </div>
              </b-col>
            </b-row>
          </div>
          <div
            class="table-responsive"
            v-if="items != undefined && items.length > 0"
          >
            <b-table
              id="filter-table"
              stacked="md"
              hover
              bordered
              small
              responsive="sm"
              :items="items"
              :fields="columns"
              no-local-sorting
            >
              <template v-slot:cell(index)="data">
                <span v-if="data.index <= pagination.to - pagination.from">{{
                  data.index + pagination.from
                }}</span>
              </template>
  
              <template v-slot:cell(sysUserName)="data">
                @{{ data.item.sysUserName }}
              </template>

              <template v-slot:cell(mainAmount)="data">
                {{ data.item.mainAmount | currecry }}
              </template>

              <template v-slot:cell(interestAmount)="data">
                {{ data.item.interestAmount | currecry }}
              </template>

              <template v-slot:cell(feeAmount)="data">
                {{ data.item.feeAmount | currecry }}
              </template>
  
              <template v-slot:cell(actions)="data">
                <b-button-group
                  v-if="data.index <= pagination.to - pagination.from"
                >
                  <b-button
                    size="sm"
                    variant="primary"
                    :to="{
                      name: 'admin.customer.detail',
                      params: { id: data.item.customerId }
                    }"
                  >
                    <i class="fa fa-id-card"></i>
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
  
            <div slot="footer" class="mb-0 mt-3">
              <nav class="pull-right" v-if="pagination.total > query.per_page">
                <b-pagination
                  class="mb-0"
                  :total-rows="pagination.total"
                  :per-page="query.per_page"
                  v-model="query.page"
                />
              </nav>
              <p class="mb-0">
                {{ pagination.total }} өгөгдлөөс
                {{ pagination.to ? pagination.from : 0 }} -
                {{ pagination.to }} өгөгдөл харагдаж байна.
              </p>
            </div>
          </div>
  
          <b-alert variant="warning" v-else show class="mb-0">
            Мэдээлэл байхгүй байна
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import moment from "moment";
  import axios from "axios";
  export default {
    name: "Purchase.Repayment.Payment",
    components: { axios },
    data: function() {
      return {
        isLoading: false,
        filterShow: false,
        isOverduePayment: false,
        query: {
          id: 0,
          page: 1,
          per_page: 10,
          orderDirection: "DESC",
          orderBy: "loan_date",
          search: "",
          columns: "",
          merchant_id: null,
          start_date: "",
          end_date: ""
        },
        startAt: "",
        endAt: "",
        payment: {
          over: "0",
          under: "0",
          undueLoss: "0"
        },
        pagination: {
          total: 0,
          from: 0,
          to: 0
        },
        orderDesc: false,
        items: [],
        selected_merchant: null,
        selected_employee: null,
        merchants: [],
        employees: [],
        search: "",
        download: {
          loading: false,
          header: {}
        },
        columns: [
          { label: "№", key: "index", class: "text-center" },
          {
            label: "Merchant",
            key: "merchantName",
            sort_key: "merchant"
          },
          {
            label: "Овог",
            key: "lastname",
            sort_key: "lastname"
          },
          {
            label: "Нэр",
            key: "firstname",
            sort_key: "firstname"
          },
          {
            label: "Регистр",
            key: "registerNumber",
            sort_key: "register"
          },
          {
            label: "Утас",
            key: "phoneNumber",
            sort_key: "phone"
          },
          {
            label: "Үндсэн зээл",
            key: "mainAmount",
            sort_key: "mainAmount",
            class: "right-center"
          },
          {
            label: "Хүү",
            key: "interestAmount",
            sort_key: "interestAmount",
            class: "right-center"
          },
          { label: "Шимтгэл", key: "feeAmount",
          class: "right-center" },
          {
            label: "Огноо",
            key: "paymentDate",
            sort_key: "date"
          },
          { label: "Төлөлтийн төрөл", key: "paymentType" }
        ],
        isActiveLoan: false
      };
    },
    created: function() {
      this.initData();
      this.$data.query.columns = [
        "firstname",
        "lastname",
        "register",
        "phone",
        "amount",
        "data"
      ].join(",");
      this.$data.query.id = this.customerId;
      this.$data.download.header = {};
      for (let i in this.$data.columns) {
        let _field = this.$data.columns[i];
        this.$data.download.header[_field.label] = _field.key;
      }
    },
    watch: {
      query: {
        handler(query) {
            if(query.merchant_id != null) this.loadData();
        },
        deep: true
      },
  
      selected_merchant: {
        handler(selected_merchant) {
          if (selected_merchant)
            this.$data.query.merchant_id = selected_merchant.id;
          else this.$data.query.merchant_id = null;
          this.loadData();
        },
        deep: true
      },
      selected_employee: {
        handler(selected_employee) {
          if (selected_employee)
            this.$data.query.created_by = selected_employee.id;
          else this.$data.query.created_by = null;
          this.loadData();
        },
        deep: true
      },
      startAt: {
        handler(startAt) {
          this.$data.query.start_date = startAt;
          this.loadData();
        },
        deep: true
      },
      endAt: {
        handler(endAt) {
          this.$data.query.end_date = endAt;
          this.loadData();
        },
        deep: true
      }
    },
    methods: {
        nameWithCode: function({name, code}) {
        return `${code} | ${name}`
        },
      initData: function() {
        this.$http
          .get(this.$config.API_URL + "PurchaseWebService/get_merchant_list", {
            params: {
              page: 1,
              per_page: 0,
              orderBy: "code",
              columns: [
                "code",
                "name",
                "register_number",
                "start_date",
                "end_date",
                "create_date"
              ].join(",")
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true
          })
          .then(
            (response) => {
              //console.log(response)
              this.$data.merchants = response.body.data;
            },
            (response) => {}
          );
      },
      loadData: function() {
        this.checkSession();
        this.isLoading = true;
        console.log(this.$data.query);
        this.$http
          .get(this.$config.API_URL + "PurchaseWebService/get_repayment_payment", {
            params: this.$data.query,
            headers: this.$store.getters.httpHeader,
            emulateJSON: true
          })
          .then(
            (response) => {
              this.isLoading = false;
              this.$data.items = response.body.data;
              this.$data.pagination.total = response.body.total;
              this.$data.pagination.from = response.body.from;
              this.$data.pagination.to = response.body.to;
            },
            (response) => {
              this.isLoading = false;
            }
          );
      },
      sortingChanged(ctx) {
        let _field = "";
        for (let i in this.fields) {
          if (this.fields[i].key == ctx.sortBy) {
            this.$data.query.orderBy = this.fields[i].sort_key;
            break;
          }
        }
        this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
      },
      submitSearch: function() {
        this.$data.query.search = this.$data.search;
      },
      async fetchData() {
        if (this.download.loading) {
          this.download.loading = true;
          const response = await axios.get(
            this.$config.API_URL + "PurchaseWebService/get_repayment_payment",
            {
              params: {
                page: 1,
                per_page: 0,
                orderDirection: this.$data.query.orderDirection,
                orderBy: this.$data.query.orderBy,
                search: this.$data.query.search,
                columns: this.$data.query.columns,
                id: this.$data.query.id,
                start_date: this.$data.query.start_date,
                end_date: this.$data.query.end_date,
                merchant_id: this.$data.query.merchant_id
              },
              headers: this.$store.getters.httpHeader,
              emulateJSON: true
            }
          );
          if (response.data.status == 500) {
            this.showToast(
              "Алдаа",
              "Алдаа гарсан байна. Дахин оролдоно уу",
              "danger"
            );
            this.download.loading = false;
            return [];
          }
  
          let _result = [];
          for (let i in response.data.data) {
            let _item = response.data.data[i];
            _item.index = parseInt(i) + 1;
            _result.push(_item);
          }
          return _result;
        }
        return [];
      },
      startDownload() {
        this.download.loading = true;
      },
      finishDownload() {
        this.download.loading = false;
      }
    }
  };
  </script>
  